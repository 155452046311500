import { createContext, ReactNode, useContext, useLayoutEffect, useMemo, useState } from 'react';
import { Location, useLocation } from 'react-router';
import BreadcrumbItem, { BreadcrumbItemProps } from '@/Layout/Navbar/BreadcrumbItem';
import chimeFile from '/sounds/chime.wav?url';
import { useAppSelector } from '@/redux/hooks';

type ToolsContextType = {
    tools: ReactNode | null;
    updateTools: (updatedTools: ReactNode) => void;
    breadcrumbs?: ReactNode;
    updateBreadcrumbs: (breadcrumbs: BreadcrumbItemProps[]) => void;
    updateTabTitle: (title: string | null) => void;
    tabTitle: string | null;
    playChime: () => void;
    modal?: ReactNode | null;
    updateModal: (modal: ReactNode | null) => void;
};

interface ToolsProps {
    children?: ReactNode;
}

export const ToolsContext = createContext<ToolsContextType>(null!);

const ToolsProvider = ({ children }: ToolsProps) => {
    const location = useLocation();
    const playSounds = useAppSelector((state) => state.user.settings.alertSounds);
    const [tools, setTools] = useState<ReactNode | null>(null);
    const [modal, setModal] = useState<ReactNode | null>(null);
    const [breadcrumbs, setBreadcrumbs] = useState<ReactNode | undefined>();
    const [previousLocation, updatePreviousLocation] = useState<Location>(null!);
    const [tabTitle, setTabTitle] = useState<ToolsContextType['tabTitle']>(null);
    const [currentPath, setCurrentPath] = useState('');
    const chime = useMemo(() => {
        const audio = new Audio(chimeFile);
        audio.volume = 0.3;

        return audio;
    }, []);

    const playChime = () => playSounds && chime.readyState === 4 && chime.play();

    const updateTools = (updatedTools: ReactNode) => {
        setTools(updatedTools);
    };

    const updateModal = (modal: ReactNode | null) => {
        setModal(modal);
    };

    const updateBreadcrumbs = (breadcrumbs: BreadcrumbItemProps[]) => {
        setBreadcrumbs(
            breadcrumbs?.map((breadcrumb, index) => (
                <BreadcrumbItem
                    key={`${breadcrumb}_${index}`}
                    {...{
                        type: breadcrumb.type,
                        name: breadcrumb.name,
                        avatar: breadcrumb.avatar,
                        uri: breadcrumb.uri,
                        skeleton: breadcrumb.skeleton,
                        chip: breadcrumb.chip,
                    }}
                />
            ))
        );
    };

    const updateTabTitle = (title: string | null) => {
        setTabTitle(title);
    };

    useLayoutEffect(() => {
        if (tabTitle) {
            document.title = `Intract - ${tabTitle}`;
        } else {
            document.title = 'Intract';
        }
    }, [tabTitle]);

    useLayoutEffect(() => {
        if (location !== previousLocation) {
            //Page Changed
            updatePreviousLocation(location);
            if (location.pathname !== currentPath) {
                updateTabTitle(null);
                updateTools(null);
                setCurrentPath(location.pathname);
            }
        }
    }, [location]);

    return (
        <ToolsContext.Provider
            value={{
                tools,
                updateTools,
                updateTabTitle,
                tabTitle,
                breadcrumbs,
                updateBreadcrumbs,
                playChime,
                modal,
                updateModal,
            }}
        >
            {children}
        </ToolsContext.Provider>
    );
};

const useTools = () => {
    return useContext(ToolsContext);
};

export default ToolsProvider;

export { useTools };
