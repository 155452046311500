import { Cloud } from '@mui/icons-material';
import {
    Card,
    CardActions,
    CardContent,
    CardHeader,
    LinearProgress,
    LinearProgressProps,
    Stack,
    Typography,
} from '@mui/material';
import { FC, useMemo } from 'react';

type OrganizationStorageBarProps = {
    organization?: Organization;
    large?: boolean;
};

const OrganizationStorageBar: FC<OrganizationStorageBarProps> = ({ organization, large }) => {
    const percent = useMemo(() => {
        if (!organization || organization.tier_display.storage_limit === -1) {
            return 0;
        }

        const value = (organization.used_storage.bytes / organization.tier_display.storage_limit) * 100;

        return value > 100 ? 100 : value;
    }, [organization]);

    const color = useMemo((): LinearProgressProps['color'] => {
        if (!organization || organization.tier_display.storage_limit === -1) {
            return 'secondary';
        }

        if (percent < 50) {
            return 'info';
        }

        if (percent < 80) {
            return 'warning';
        }

        return 'error';
    }, [organization, percent]);

    if (!organization) {
        return null;
    }

    if (large) {
        return (
            <>
                <Card elevation={1}>
                    <CardHeader
                        sx={{ pt: 0.5, pb: 1 }}
                        title={
                            <>
                                <Cloud sx={{ mr: 0.5, fontSize: 20 }} /> Storage
                            </>
                        }
                        titleTypographyProps={{
                            display: 'flex',
                            alignItems: 'center',
                            variant: 'body1',
                            justifyContent: 'center',
                        }}
                    />
                    <CardContent sx={{ paddingY: 0, paddingX: 0.5 }}>
                        <LinearProgress
                            sx={{ height: 12, borderRadius: 1 }}
                            color={color}
                            variant={'determinate'}
                            value={percent}
                        />
                    </CardContent>
                    <CardActions sx={{ justifyContent: 'center' }}>
                        <Typography noWrap={true} variant={'body2'}>
                            {organization.used_storage.human} / {organization.tier_display.storage_limit_human}
                        </Typography>
                    </CardActions>
                </Card>
            </>
        );
    }

    return (
        <Stack spacing={0.2} sx={{ width: 175, justifyContent: 'center' }}>
            <div style={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                <Cloud sx={{ mr: 0.5, fontSize: 18 }} />
                <Typography noWrap={true} variant={'body2'}>
                    {organization.used_storage.human} / {organization.tier_display.storage_limit_human}
                </Typography>
            </div>
            <LinearProgress
                sx={{ height: 10, borderRadius: 1 }}
                color={color}
                variant={'determinate'}
                value={percent}
            />
        </Stack>
    );
};

export default OrganizationStorageBar;
