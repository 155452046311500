import { Chip } from '@mui/material';
import { FC, useMemo } from 'react';
import { NewReleases, Verified } from '@mui/icons-material';

type UserSubscriptionChipProps = {
    subscription?: UserSubscription | null;
};

const UserSubscriptionChip: FC<UserSubscriptionChipProps> = ({ subscription }) => {
    const label = useMemo(() => {
        if (!subscription) {
            return 'Not subscribed';
        }

        const tier = `${subscription.price?.name} ${subscription.price?.frequency}`;

        const status = !subscription.is_valid ? ' - Ended' : subscription.is_canceled ? ' - Canceled' : undefined;

        return `${tier}${status ?? ''}`;
    }, [subscription]);

    if (subscription === undefined) {
        return null;
    }

    return (
        <Chip
            size={'small'}
            label={label}
            color={
                !subscription
                    ? undefined
                    : !subscription.is_valid
                      ? 'error'
                      : subscription?.is_canceled
                        ? 'info'
                        : 'success'
            }
            variant={'outlined'}
            icon={subscription?.is_valid ? <Verified /> : <NewReleases />}
        />
    );
};

export default UserSubscriptionChip;
