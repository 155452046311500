import { Alert, AlertTitle, Button, Stack } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { useGetUserBillingMutation } from '@/redux/features/userBillingApi';
import { Payment } from '@mui/icons-material';
import { Spinner } from 'react-activity';

type UserBillingPortalButtonProps = {
    setProcessing?: (state: boolean) => void;
};

const UserBillingPortalButton: FC<UserBillingPortalButtonProps> = ({ setProcessing }) => {
    const [errorMsg, setErrorMsg] = useState<string | null>(null);
    const [getBilling, { data, isLoading, error }] = useGetUserBillingMutation();

    const handleBilling = () => {
        setProcessing && setProcessing(true);

        getBilling(null);
    };

    useEffect(() => {
        if (error) {
            const response = error as BasicError;
            setErrorMsg(response.data.message);
            setProcessing && setProcessing(false);
        }
    }, [error]);

    useEffect(() => {
        if (data) {
            window.location.href = data.url;
        }
    }, [data]);

    return (
        <Stack sx={{ width: '100%' }}>
            {errorMsg && (
                <Alert sx={{ marginBottom: 2 }} severity={'info'}>
                    <AlertTitle>{errorMsg}</AlertTitle>
                </Alert>
            )}
            <Button
                fullWidth={true}
                disabled={isLoading || !!data}
                startIcon={isLoading || !!data ? <Spinner /> : <Payment />}
                variant={'contained'}
                color={'secondary'}
                size={'large'}
                onClick={handleBilling}
            >
                Manage Subscription (Stripe)
            </Button>
        </Stack>
    );
};

export default UserBillingPortalButton;
