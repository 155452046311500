type WorkspaceRoute = {
    workspaceId?: string;
    workspaceProjectId?: string;
};

const useWorkspaceRoute = (): WorkspaceRoute => {
    const pathParts = window.location.pathname.split('/').filter(Boolean);

    if (pathParts[0] === 'organizations') {
        return {
            workspaceId: pathParts[1],
            workspaceProjectId: pathParts[2] === 'projects' ? pathParts[3] : undefined,
        };
    }

    return {
        workspaceId: undefined,
        workspaceProjectId: undefined,
    };
};

export default useWorkspaceRoute;
