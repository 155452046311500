import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import userApi from '@/redux/features/userApi';
import authApi from '@/redux/features/authApi';

const initialState: UserState = {
    data: null!,
    name: null!,
    settings: {
        admin: false,
        verified: null,
        darkMode: true,
        alertSounds: false,
    },
};

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        toggleDarkMode: (state, action: PayloadAction<boolean | undefined>) => {
            state.settings = { ...state.settings, darkMode: action.payload ?? !state.settings.darkMode };
        },
        toggleAlertSounds: (state, action: PayloadAction<boolean | undefined>) => {
            state.settings = { ...state.settings, alertSounds: action.payload ?? !state.settings.alertSounds };
        },
        logout: (state) => {
            state.data = null!;
            state.name = null!;
            state.settings = { admin: false, verified: null, darkMode: true, alertSounds: false };
        },
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(authApi.endpoints.logout.matchFulfilled, (state) => {
                state.data = null!;
                state.name = null!;
                state.settings = { admin: false, verified: null, darkMode: true, alertSounds: false };
            })
            .addMatcher(userApi.endpoints.getUser.matchFulfilled, (state, { payload }) => {
                state.name = payload.name;
                state.settings = {
                    admin: payload.is_admin,
                    verified: payload.is_verified,
                    darkMode: payload.dark_mode ?? state.settings.darkMode ?? true,
                    alertSounds: state.settings.alertSounds ?? false,
                };
                state.data = payload;
            });
    },
});

export default userSlice;
// eslint-disable-next-line no-empty-pattern
export const { logout, toggleDarkMode, toggleAlertSounds } = userSlice.actions;
