import { FC, MouseEvent, useEffect, useState } from 'react';
import {
    Alert,
    AlertTitle,
    Button,
    Card,
    CardActionArea,
    CardActions,
    CardContent,
    CardHeader,
    Chip,
    Divider,
    Grid,
    Stack,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
} from '@mui/material';
import { CheckCircle, Info } from '@mui/icons-material';
import useDateUtils from '@/hooks/useDateUtils';
import { useSearchParams } from 'react-router-dom';
import UserBillingPortalButton from '@/Billing/User/UserBillingPortalButton';
import UserCheckoutButton from '@/Billing/User/UserCheckoutButton';
import { useAppSelector } from '@/redux/hooks';
import { useGetUserQuery } from '@/redux/features/userApi';

interface UserPricingTier {
    title: string;
    priceMonthly: number;
    stripePriceMonthly: UserPrice;
    priceYearly: number;
    stripePriceYearly: UserPrice;
    storage: string;
}

type Frequency = 'month' | 'year';

type UserSubscriptionProps = {
    setProcessing?: (state: boolean) => void;
};

const UserSubscription: FC<UserSubscriptionProps> = ({ setProcessing }) => {
    const { toLocaleDateString } = useDateUtils();
    const [searchParams] = useSearchParams();
    const [poll, setPoll] = useState<boolean>(false);
    const [thank, setThank] = useState<boolean>(false);
    const auth = useAppSelector((state) => state.auth);

    const { data: user } = useGetUserQuery(null, {
        pollingInterval: poll ? 7000 : undefined,
        skip: (!auth.pkce.accessToken || !auth.xsrfToken) && !auth.status,
    });
    const [frequency, setFrequency] = useState<Frequency>(
        user?.current_subscription?.price?.frequency === 'yearly' ? 'year' : 'month'
    );
    const [selected, setSelected] = useState<UserPrice>(user?.current_subscription?.price?.alias ?? 'tier-1-monthly');

    const handleViewType = (_event: MouseEvent<HTMLElement>, newAlignment: string | null) => {
        if (newAlignment !== null) {
            setFrequency(newAlignment as Frequency);
        }
    };

    const updateSelected = (price: UserPrice) => {
        setSelected(price);
    };

    useEffect(() => {
        const action = searchParams.get('action');

        if (action === 'subscribed') {
            setThank(true);
            setPoll(!user?.current_subscription);
        }
    }, [user?.current_subscription]);

    return (
        <>
            {thank && (
                <Alert sx={{ marginBottom: 2 }} severity={'success'}>
                    <AlertTitle>
                        Thank you for subscribing!
                        {poll
                            ? ' Your benefits will be updated momentarily once payment processing has completed.'
                            : ' Your benefits have been updated.'}
                    </AlertTitle>
                </Alert>
            )}
            {user?.current_subscription?.is_valid && user.current_subscription.is_canceled && (
                <>
                    <Alert severity={'info'}>
                        <AlertTitle>
                            Your subscription has been canceled. Your benefits will expire on{' '}
                            {toLocaleDateString(user.current_subscription.ends_at ?? '')}
                        </AlertTitle>
                    </Alert>
                    <Divider sx={{ my: 2.5 }} />
                </>
            )}
            {user?.current_subscription && !user.current_subscription.is_valid && (
                <>
                    <Alert severity={'warning'}>
                        <AlertTitle>
                            Your subscription ended {toLocaleDateString(user.current_subscription.ends_at ?? '')}.
                            Reactivate your subscription below!
                        </AlertTitle>
                    </Alert>
                    <Divider sx={{ my: 2.5 }} />
                </>
            )}
            <ToggleButtonGroup color={'info'} value={frequency} exclusive={true} onChange={handleViewType}>
                <ToggleButton value={'month'}>Monthly</ToggleButton>
                <ToggleButton value={'year'}>Yearly</ToggleButton>
            </ToggleButtonGroup>
            <Grid container spacing={3} justifyContent={'center'} marginTop={0.5}>
                {pricingTiers.map((tier) => (
                    <Grid xs={4} item key={tier.title}>
                        <Card
                            sx={{
                                backgroundColor:
                                    (frequency === 'month' && selected === tier.stripePriceMonthly) ||
                                    (frequency === 'year' && selected === tier.stripePriceYearly)
                                        ? 'secondary.dark'
                                        : undefined,
                            }}
                            elevation={
                                (frequency === 'month' && selected === tier.stripePriceMonthly) ||
                                (frequency === 'year' && selected === tier.stripePriceYearly)
                                    ? 15
                                    : 3
                            }
                        >
                            <CardActionArea
                                disabled={
                                    !!user?.current_subscription?.is_valid ||
                                    (frequency === 'month' && selected === tier.stripePriceMonthly) ||
                                    (frequency === 'year' && selected === tier.stripePriceYearly)
                                }
                                onClick={() =>
                                    updateSelected(
                                        frequency === 'month' ? tier.stripePriceMonthly : tier.stripePriceYearly
                                    )
                                }
                            >
                                <CardHeader
                                    title={
                                        user?.current_subscription &&
                                        ((frequency === 'month' &&
                                            user.current_subscription.price?.alias === tier.stripePriceMonthly) ||
                                            (frequency === 'year' &&
                                                user.current_subscription.price?.alias === tier.stripePriceYearly)) ? (
                                            <Stack spacing={1} alignItems={'center'} direction={'row'}>
                                                <Typography variant={'h4'}>{tier.title}</Typography>
                                                <Chip
                                                    size={'small'}
                                                    label={user.current_subscription.is_valid ? 'Current' : 'Previous'}
                                                    color={user.current_subscription.is_valid ? 'info' : 'primary'}
                                                    variant={'filled'}
                                                    icon={
                                                        user.current_subscription.is_valid ? <CheckCircle /> : <Info />
                                                    }
                                                />
                                            </Stack>
                                        ) : (
                                            tier.title
                                        )
                                    }
                                    subheader={tier.storage}
                                    titleTypographyProps={{
                                        variant: 'h4',
                                    }}
                                />
                                <CardContent>
                                    <Typography variant={'h5'}>
                                        ${frequency === 'month' ? tier.priceMonthly : tier.priceYearly} /{' '}
                                        <Typography variant={'caption'}>per {frequency}</Typography>
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                            {!user?.current_subscription?.is_valid && (
                                <CardActions sx={{ justifyContent: 'center' }}>
                                    <Button
                                        fullWidth={true}
                                        variant={'contained'}
                                        color={'inherit'}
                                        disabled={
                                            (frequency === 'month' && selected === tier.stripePriceMonthly) ||
                                            (frequency === 'year' && selected === tier.stripePriceYearly)
                                        }
                                        onClick={() =>
                                            updateSelected(
                                                frequency === 'month' ? tier.stripePriceMonthly : tier.stripePriceYearly
                                            )
                                        }
                                    >
                                        {(frequency === 'month' && selected === tier.stripePriceMonthly) ||
                                        (frequency === 'year' && selected === tier.stripePriceYearly)
                                            ? 'Selected'
                                            : 'Select'}
                                    </Button>
                                </CardActions>
                            )}
                        </Card>
                    </Grid>
                ))}
            </Grid>
            <Divider sx={{ my: 2.5 }} />
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                {user?.current_subscription?.is_valid ? (
                    <UserBillingPortalButton {...{ setProcessing }} />
                ) : (
                    <UserCheckoutButton {...{ price: selected, setProcessing }} />
                )}
            </div>
        </>
    );
};

const pricingTiers: UserPricingTier[] = [
    {
        title: 'Tier 1',
        priceMonthly: 0.99,
        stripePriceMonthly: 'tier-1-monthly',
        priceYearly: 9.99,
        stripePriceYearly: 'tier-1-yearly',
        storage: '5 GB of storage',
    },
    {
        title: 'Tier 2',
        priceMonthly: 2.99,
        stripePriceMonthly: 'tier-2-monthly',
        priceYearly: 29.99,
        stripePriceYearly: 'tier-2-yearly',
        storage: '25 GB of storage',
    },
    {
        title: 'Tier 3',
        priceMonthly: 5.99,
        stripePriceMonthly: 'tier-3-monthly',
        priceYearly: 59.99,
        stripePriceYearly: 'tier-3-yearly',
        storage: '50 GB of storage',
    },
];

export default UserSubscription;
