import { FC, KeyboardEvent, useEffect, useMemo, useState } from 'react';
import {
    Avatar,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    ListItemAvatar,
    MenuItem,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import { useStoreOrganizationMutation } from '@/redux/features/organizationApi';
import { useNavigate } from 'react-router-dom';
import { Business, DomainAdd } from '@mui/icons-material';
import { Sentry } from 'react-activity';

type AddOrganizationProps = {
    asButton?: boolean;
    asMenuItem?: boolean;
};

const AddOrganization: FC<AddOrganizationProps> = ({ asButton, asMenuItem }) => {
    const [createOrg, { data: organization, error, isSuccess, isLoading, reset }] = useStoreOrganizationMutation();
    const [name, setName] = useState<string>('');
    const [open, setOpen] = useState<boolean>(false);
    const [nameError, setNameError] = useState<string>('');
    const disabled = useMemo((): boolean => name.trim().length < 2, [name]);
    const navigate = useNavigate();
    const elmId = useMemo(() => Date.now(), []);

    const handleSubmit = () => {
        !disabled && createOrg({ name });
    };

    const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>): void => {
        e.key === 'Enter' && handleSubmit();
    };

    const handleClose = () => {
        setOpen(false);
        setName('');
        setNameError('');
    };

    useEffect(() => {
        if (isSuccess && organization) {
            setName('');
            setOpen(false);
            reset();
            navigate(`/organizations/${organization.id}/billing`);
        }
    }, [isSuccess, organization, navigate]);

    useEffect(() => {
        if (error) {
            const response = error as BasicError;
            setNameError(response.data.message);
        }
    }, [error]);

    return (
        <>
            {asMenuItem ? (
                <Tooltip placement={'right'} title={<Typography>Create Organization</Typography>} arrow>
                    <MenuItem onClick={() => setOpen(true)}>
                        <ListItemAvatar>
                            <Avatar
                                sx={{
                                    backgroundColor: (theme) => theme.palette.text.secondary,
                                }}
                            >
                                <DomainAdd sx={{ fontSize: 28 }} />
                            </Avatar>
                        </ListItemAvatar>
                    </MenuItem>
                </Tooltip>
            ) : asButton ? (
                <Button
                    size={'large'}
                    startIcon={<DomainAdd />}
                    variant={'contained'}
                    color={'secondary'}
                    onClick={() => setOpen(true)}
                >
                    Create
                </Button>
            ) : (
                <IconButton size={'large'} onClick={() => setOpen(true)}>
                    <DomainAdd sx={{ fontSize: 26 }} />
                </IconButton>
            )}
            <Dialog
                PaperProps={{
                    elevation: 0,
                }}
                open={open}
                fullWidth={true}
                maxWidth={'sm'}
                onClose={handleClose}
            >
                <DialogTitle>Create a new organization:</DialogTitle>
                {isLoading || isSuccess ? (
                    <Grid padding={1} container display={'flex'} justifyContent={'center'}>
                        <Sentry style={{ fontSize: 30 }} />
                    </Grid>
                ) : (
                    <>
                        <DialogContent>
                            <TextField
                                error={!!nameError.length}
                                helperText={nameError.length ? nameError : ''}
                                autoFocus
                                margin={'dense'}
                                id={`organization-name-${elmId}`}
                                label={'Organization Name'}
                                type={'text'}
                                fullWidth
                                variant={'outlined'}
                                onChange={(e) => setName(e.target.value)}
                                onKeyDown={handleKeyDown}
                                value={name}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button variant={'contained'} color={'inherit'} onClick={handleClose}>
                                Cancel
                            </Button>
                            <Button
                                disabled={disabled}
                                startIcon={<Business />}
                                variant={'contained'}
                                color={'secondary'}
                                onClick={handleSubmit}
                            >
                                Create
                            </Button>
                        </DialogActions>
                    </>
                )}
            </Dialog>
        </>
    );
};

export default AddOrganization;
